/*eslint-disable*/
import React, { useState, useEffect } from "react";
/*eslint-disable*/

import { MarketPlaceLoadStateHandler } from "../../react/MarketPlace";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import { flow } from "fp-ts/lib/function";
import styled from "styled-components";
import loadScript from "../../react/loadscript";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import KeyResources from "../../components/common/KeyResources";
import { useMedia } from "use-media";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";

export const blogImg = require("../../assets/img/key-resources/bc_1_4.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

// const topImage = require("../../assets/images/automate_usp/marketplace_img.png");
const topImage = require("../../assets/images/automate_usp/marketplace_600.png");
const { RequestForm } = require("../../components/form");

const WrapperContent = styled.div`
width:100%:
float:left;

@media (max-width:767px){
  .bg_marketpalce .cc_feature_apps_header h3 {
    text-align: center;
    font-family: "Roboto-Medium";
    font-size: 26px;
    padding: 0;
 
}
}
@media (max-width:520px){
  .bg_marketpalce .cc_feature_apps_header h3 {
    text-align: center;
    font-family: "Roboto-Medium";
    font-size: 24px;
    padding: 0;
}
}
`;

export default function MarketPlace(props) {
  //   const e = React.createElement;

  //   const MarketPlace = e(MarketPlaceLoadStateHandler);

  return (
    <>
      <TitleAndMetaTags
        title="Pre-built app workflows and actions for chatbot | Workativ Assistant Chatbot"
        description="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations"
        ]}
        ogImage={topImage}
        ogTitle="Pre-built app workflows and actions for chatbot | Workativ Assistant Chatbot"
        ogDescription="Integrate chatbot with apps and use pre-built workflow actions and tasks from marketplace easily. Explore the full potential of chatbot based automations instantly."
      />
      <div className="marketplace_assistant">
        <Container>
          <Layout
            backgroundColor={" bg_slider_home pad_0"}
            logoFor="ASSISTANT"
            product="ASSISTANT"
          >
            <PostHeaderSection />
            <MarketPlaceLoadStateHandler {...props} />
            <WrapperContent>
              <ReachToUsSection />
            </WrapperContent>
            <KeyResources cards={resoucesData} />
            <RequestForm isFooterForm={true} />
            {/* <OnScrollPopup /> */}
          </Layout>
        </Container>
      </div>
    </>
  );
}

function Loader() {
  return (
    <div className="loader_demo" id="loader_demo">
      <div className="loader"></div>
    </div>
  );
}

function PostHeaderSection() {
  return (
    <RegisterSection rightImage={topImage} backgroundColor={" bg_slider_home "} altImage="App Workflow Marketplace for Chatbot">
      <RegisterSection.FormWithContent>
        <RegisterSection.MainHeader>
          Deliver workflow automation in seconds. Just download. No coding.
        </RegisterSection.MainHeader>
        <RegisterSection.SubHeader>
          Pick from over 600+ pre-built automations on popular apps, and
          automate your employee support.
        </RegisterSection.SubHeader>
      </RegisterSection.FormWithContent>
    </RegisterSection>
  );
}

function PreFooterSection() {
  return (
    <div className="container-fluid pl-0 pr-0">
      <img loading = "lazy" src={require("../../react/images/mp_footer.png")} alt="footer_img" />
    </div>
  );
}

export function ReachToUsSection() {
  const [showModal, setShowModal] = useState(false);

  const [state, setState] = useState({ useCase: "", email: "" });

  function closemodel(callback) {
    document.getElementById("exampleModalCenter").className = "modal fade";
    document.getElementById("exampleModalCenter").style.display = "none";
    if (callback) {
      callback();
    }

    setShowModal(false);
  }

  function enableSubmit() {
    return state.useCase.length > 0 && state.email.length > 0;
  }

  useEffect(() => {
    // console.log("load scropt");
    if (!window._load_script) {
      loadScript("/marketPlaceScript.js")
        .then(script => {
          // console.log("load scropt success");
          // console.log(script);
          // Grab the script object in case it is ever needed.
          // this.mapScript = script;
          // this.setState({ apiLoaded: true });
        })
        .catch(err => {
          console.log("load scropt error");
          console.error(err.message);
        });
    }
  }, []);
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <section className="apps_integrations bg_marketpalce">
      <div className="cogni_fav_apps ">
        <div className="container">
          <div className="marketplace_integrations_icons">
            <div className="cc_feature_apps_header">
              {isSmall ? (
                <h3>
                  Didn’t find the automation you were looking for? No worries,
                  we will create one for you.
                </h3>
              ) : (
                <h3>
                  Didn’t find the automation you were looking for? <br></br>No
                  worries, we will create one for you.
                </h3>
              )}
              <div className="modal_submit_form">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModalCenter1"
                  onClick={() => setShowModal(true)}
                >
                  Reach out to us
                </button>

                <div
                  className="modal fade show"
                  id="exampleModalCenter1"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  style={{ display: showModal ? "flex" : "none" }}
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div
                      className="modal-content modal-content-popup"
                      id="beta-detail-page"
                    >
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={() => closemodel()}
                        >
                          <span className="btn_close_popup" aria-hidden="true">
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="modal-body-header">
                        <div className="modal-body">
                          <form
                            id="_form_22_"
                            className="_form _form_22 _inline-form  _dark"
                            noValidate
                          >
                            <input type="hidden" name="u" defaultValue={22} />
                            <input type="hidden" name="f" defaultValue={22} />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" defaultValue={0} />
                            <input type="hidden" name="m" defaultValue={0} />
                            <input
                              type="hidden"
                              name="act"
                              defaultValue="sub"
                            />
                            <input type="hidden" name="v" defaultValue={2} />
                            <div className="_form-content">
                              <div className="_form_element _x41893381 _full_width _clear">
                                <div className="_form-title">
                                  Request for Use Case
                                </div>
                              </div>
                              <div className="_form_element _x65116826 _full_width _clear">
                                <div className="_html-code">
                                  <p>
                                    Tell us of your use case requirement in a
                                    few lines.
                                  </p>
                                </div>
                              </div>
                              <div className="_form_element _field16 _full_width ">
                                <label className="_form-label">
                                  Automation use case *
                                </label>
                                <div className="_field-wrapper">
                                  <textarea
                                    id="useCase"
                                    name="field[16]"
                                    placeholder="Automation use case "
                                    required
                                    defaultValue={""}
                                    onChange={e =>
                                      setState({
                                        ...state,
                                        useCase: e.target.value
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_form_element _x68473619 _full_width ">
                                <label className="_form-label">Email*</label>
                                <div className="_field-wrapper">
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    placeholder="Type your email"
                                    required
                                    onChange={e =>
                                      setState({
                                        ...state,
                                        email: e.target.value
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="_button-wrapper _full_width">
                                <button
                                  id="_form_22_submit"
                                  className="_submit"
                                  type="submit"
                                  disabled={!enableSubmit()}
                                  style={{
                                    pointerEvents: enableSubmit()
                                      ? "visible"
                                      : "none"
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="_clear-element" />
                            </div>
                            <div
                              className="_form-thank-you"
                              style={{ display: "none" }}
                            />
                          </form>
                        </div>
                        <div className="modal_body_right">
                          <img loading = "lazy"
                            src={require("../../assets/images/landing/register.png")}
                            alt="register"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const resoucesData = [
  {
    image: blogImg,
    header:
      "Browse through topics on AI, Automation, Chatbots and Workplace support",
    url: "https://blog.workativ.com/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "What’s the real value for business in transforming workplace support?",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header:
      "Watch videos to learn about all the capabilities of Workativ Assistant",
    url: "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    link: "Watch Now",
    alt: "videoImg"
  }
];
